<template>
  <modal-base 
    v-model="vShow" 
    title="Opciones de campo"
    @after-enter="onMain"
    @cancel="$emit(`cancel`)">
    <FormForMedidor 
      v-if="tipo === `medidor`"
      ref="refmedidor"
      v-model="form"
      @onsuccess="onClose" />
    <FormForNumero 
      v-else
      ref="refnumero"
      v-model="form"
      @onsuccess="onClose" />
    <template #footer>
      <b-button
        type="is-secondary"
        icon-left="times"
        @click="handleClose"
        >Cerrar</b-button
      >
    </template>
  </modal-base>
</template>
<script>
import FormForMedidor from "./FormForMedidor.vue"
import FormForNumero from "./FormForNumero.vue"
export default {
  props:{
    value:{
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: () => false,
    },
    tipo: {
      type: String,
      default: () => `medidor`
    }
  },
  components:{
    FormForMedidor,
    FormForNumero,
  },
  computed:{
    form :{
      get(){
        return this.value;
      },
      set(e){
        this.$emit("input",e)
      }
    },
    vShow:{
      get(){
        return this.show
      },
      set(e){
        this.$emit("modal", e)
      }
    }
  },
  methods: {
    onMain(){

    },
    onClose(){
      this.vShow = !this.vShow
      this.$emit(`cancel`)
    },
    handleClose(){
      const $ref = this.tipo === `medidor` ? this.$refs.refmedidor : this.$refs.refnumero
      if($ref){
        $ref.onSubmit()
      } 
    }
  }
}
</script>