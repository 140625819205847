<template>
  <div>
    <p class="has-text-weight-bold">Métricas</p>
    <draggable 
      tag="ul" 
      class="drag-group" 
      v-model="list"
      group="people" 
      ghost-class="drag-ghost" >
      <li 
        class="drag-item" 
        v-for="(element,k) in list" 
        :key="`${element.id}_${k}`">
          {{element.newLabel}} 
          <span @click.prevent="handleClick(element)" class="is-clickable has-text-info">
            <b-icon icon="info-circle" />
          </span>
      </li>
    </draggable>
    <ModalForm 
      v-model="form"
      :tipo="tipo"
      :show="showModal"
      @modal="showModal = !showModal"
      @cancel="handleClose" />
  </div>
</template>

<script>
import ModalForm from "./Modal.vue"
export default {
  props:{
    value: {
      type: Array,
      default: () => []
    },
    tipo: {
      type: String,
      default: () => `medidor`
    }
  },
  components: {
    ModalForm
  },
  data(){
    return {
      showModal: false,
      form : {}
    }
  },
  computed:{
    list:{
      get(){
        return this.value || []
      },
      set(y){
        const e = y.filter(e => e.type === "number" || e.type === "custom")
        if(e.length){
          const index = e.findIndex(r => !r.newLabel)
          if(index >= 0) {
            e[index].newLabel = e[index].label
            const m = {
              avgSection:{
                color: "#FF9900",
                from : "34",
                to: "66",
              },
              maxLimit: 100,
              maxSection:{
                color: "#DC3912",
                from : "67",
                to: "100",
              },
              minLimit: 0,
              minSection:{
                color: "#F3F8FF",
                from : "0",
                to: "33",
              },
            }
            e[index].settings = {
              ...e[index].settings,
              ...(this.tipo === `medidor` ? m : {})
            }
          }
        }
        this.$emit("input", [...e])
      }
    }
  },
  methods:{
    handleClick(e){
      this.form = {
        id: e.id,
        label: e.label,
        type: e.type,
        etiqueta: e.newLabel,
        resumir: e.aggregateFunc,
        antes: e.settings.before,
        despues: e.settings.after,
        distinct: e.distinct,
        ...(
          this.tipo === `medidor` 
            ? {
              valor_minimo: e.settings.minLimit,
              valor_maximo: e.settings.maxLimit,
              minima_desde: e.settings.minSection.from,
              minima_hasta: e.settings.minSection.to,
              minima_color: e.settings.minSection.color,
              media_desde: e.settings.avgSection.from,
              media_hasta: e.settings.avgSection.to,
              media_color: e.settings.avgSection.color,
              maxima_desde: e.settings.maxSection.from,
              maxima_hasta: e.settings.maxSection.to,
              maxima_color: e.settings.maxSection.color,
            } 
            : {
              size: e.settings.size || 44,
              color: e.settings.color || "#000000",
            }
        )
      }
      this.showModal = true
    },
    handleClose(){
      if(this.form.etiqueta){
        const index = this.list.findIndex(r => r.id === this.form.id)
        if(index >= 0){
          const f = JSON.parse(JSON.stringify(this.list[index]))
          f.newLabel = this.form.etiqueta,
          f.aggregateFunc = this.form.resumir,
          f.settings.before = this.form.antes
          f.settings.after = this.form.despues

          if(this.tipo === `medidor`){
            f.settings.minLimit = this.form.valor_minimo
            f.settings.maxLimit = this.form.valor_maximo
            f.settings.minSection.from = this.form.minima_desde
            f.settings.minSection.to = this.form.minima_hasta
            f.settings.minSection.color = this.form.minima_color
            f.settings.avgSection.from = this.form.media_desde
            f.settings.avgSection.to = this.form.media_hasta
            f.settings.avgSection.color = this.form.media_color
            f.settings.maxSection.from = this.form.maxima_desde
            f.settings.maxSection.to = this.form.maxima_hasta
            f.settings.maxSection.color = this.form.maxima_color
          } else {
            f.settings.size = this.form.size
            f.settings.color = this.form.color
          }
          this.list.splice(index, 1, f)
        }
      }
    }
  }
}
</script>