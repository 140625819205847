<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <p class="mb-2">Campo de origen: <strong>{{form.label}}</strong></p>
    <div class="columns is-flex-wrap-wrap">
      <div class="column is-6">
        <input-base
          rules="required"
          label="Etiqueta para mostrar"
          maxlength="150"
          v-model="form.etiqueta"
          focus
        />
      </div>
      <div class="column is-6" v-if="form.type === 'number'">
        <select-base
          v-show="!form.distinct"
          rules="required"
          label="Resumir por"
          v-model="form.resumir"
          :options="opciones"
          expanded
        />
      </div>
      <div class="column is-6" v-if="form.type === 'number'">
        <input-base
          rules=""
          label="Mostrar antes del dato"
          placeholder="Antes" 
          maxlength="150"
          v-model="form.antes"
        />
      </div>
      <div class="column is-6" v-if="form.type === 'number'">
        <input-base
          rules=""
          label="Mostrar después del dato"
          placeholder="Después"
          maxlength="150"
          v-model="form.despues"
        />
      </div>
    </div>
    <div class="columns is-flex-wrap-wrap">
      <div class="column is-6">
        <select-base
          rules=""
          label="Tamaño de fuente"
          v-model="form.size"
          :options="opcionesSizes"
          expanded
        />
      </div>
      <div class="column is-6">
        <input-color-base
          rules=""
          label="Color de letra"
          v-model="form.color"
          :show-input="false"
        />
      </div>
    </div>
    <input type="submit" class="is-hidden" />
  </form-observer>
</template>
<script>
export default {
  props:{
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed:{
    form:{
      get(){
        return this.value
      },
      set(e){
        this.$emit("input",e)
      }
    },
    opciones(){
      return [
        {id: `count`, value: `Recuento`},
        {id: `sum`, value: `Suma`},
        {id: `avg`, value: `Promedio`},
        {id: `min`, value: `Mínimo`},
        {id: `max`, value: `Máximo`},
      ]
    },
    opcionesSizes(){
      return [
        30,35,40,42,44,46,48,50
      ]
    }
  },
  methods: {
    async onSubmit(){
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.$emit('onsuccess')
    }
  }
}
</script>