<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <p class="mb-2">Campo de origen: <strong>{{form.label}}</strong></p>
    <div class="columns is-flex-wrap-wrap">
      <div class="column is-6">
        <input-base
          rules="required"
          label="Etiqueta para mostrar"
          maxlength="150"
          v-model="form.etiqueta"
          focus
        />
      </div>
      <div class="column is-6">
        <select-base
          v-show="!form.distinct"
          rules="required"
          label="Resumir por"
          v-model="form.resumir"
          :options="opciones"
          expanded
        />
      </div>
      <div class="column is-6">
        <input-base
          rules=""
          label="Mostrar antes del dato"
          placeholder="Antes"
          maxlength="50"
          v-model="form.antes"
        />
      </div>
      <div class="column is-6">
        <input-base
          rules=""
          label="Mostrar después del dato"
          placeholder="Después"
          maxlength="50"
          v-model="form.despues"
        />
      </div>
      <div class="column is-6">
        <input-base
          rules="required|decimal"
          label="Valor mínimo"
          placeholder="0"
          maxlength="50"
          v-model="form.valor_minimo"
        />
      </div>
      <div class="column is-6">
        <input-base
          rules="required|decimal"
          label="Valor máximo"
          placeholder="100"
          maxlength="50"
          v-model="form.valor_maximo"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <b-collapse class="card" animation="slide" aria-id="contentIdForA11y1">
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              aria-controls="contentIdForA11y1">
              <p class="card-header-title">
                Sección mínima
              </p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'chevron-down' : 'chevron-up'" size="is-small"></b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="columns">
              <div class="column">
                <input-base
                  rules="required|decimal"
                  label="Desde"
                  v-model="form.minima_desde"
                  size="is-small"
                />
              </div>
              <div class="column">
                <input-base
                  rules="required|decimal"
                  label="Hasta"
                  v-model="form.minima_hasta"
                  size="is-small"
                />
              </div>
              <div class="column">
                <input-color-base
                  rules="required"
                  label="Color"
                  v-model="form.minima_color"
                  :show-input="false"
                  size="is-small"
                />
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <b-collapse class="card" animation="slide" aria-id="contentIdForA11y2" >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              aria-controls="contentIdForA11y2">
              <p class="card-header-title">
                Sección media
              </p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'chevron-down' : 'chevron-up'" size="is-small"></b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="columns">
              <div class="column">
                <input-base
                  rules="required|decimal"
                  label="Desde"
                  v-model="form.media_desde"
                  size="is-small"
                />
              </div>
              <div class="column">
                <input-base
                  rules="required|decimal"
                  label="Hasta"
                  v-model="form.media_hasta"
                  size="is-small"
                />
              </div>
              <div class="column">
                <input-color-base
                  rules="required"
                  label="Color"
                  v-model="form.media_color"
                  :show-input="false"
                  size="is-small"
                />
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3" >
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              aria-controls="contentIdForA11y3">
              <p class="card-header-title">
                Sección máxima
              </p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'chevron-down' : 'chevron-up'" size="is-small"></b-icon>
              </a>
            </div>
          </template>
          <div class="card-content">
            <div class="columns">
              <div class="column">
                <input-base
                  rules="required|decimal"
                  label="Desde"
                  v-model="form.maxima_desde"
                  size="is-small"
                />
              </div>
              <div class="column">
                <input-base
                  rules="required|decimal"
                  label="Hasta"
                  v-model="form.maxima_hasta"
                  size="is-small"
                />
              </div>
              <div class="column">
                <input-color-base
                  rules="required"
                  label="Color"
                  v-model="form.maxima_color"
                  :show-input="false"
                  size="is-small"
                />
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <input type="submit" class="is-hidden" />
  </form-observer>
</template>
<script>
export default {
  props:{
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed:{
    form:{
      get(){
        return this.value
      },
      set(e){
        this.$emit("input",e)
      }
    },
    opciones(){
      return [
        {id: `count`, value: `Recuento`},
        {id: `sum`, value: `Suma`},
        {id: `avg`, value: `Promedio`},
        {id: `min`, value: `Mínimo`},
        {id: `max`, value: `Máximo`},
      ]
    }
  },
  watch:{
    'form.valor_minimo'(e){
      if(e && !isNaN(parseFloat(e)) && isFinite(e)){
        this.recalculate();
      }
    },
    'form.valor_maximo'(e){
      if(e && !isNaN(parseFloat(e)) && isFinite(e)){
        this.recalculate();
      }
    },
  },
  methods: {
    async onSubmit(){
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.$emit('onsuccess')
    },
    recalculate(){
      const t = parseFloat(this.form.valor_maximo) - parseFloat(this.form.valor_minimo)
      const tr = parseInt(t ? t/3 : 0)
      if(tr){
        this.$set(this.form,"minima_desde",this.form.valor_minimo)
        this.$set(this.form,"minima_hasta",parseFloat(this.form.valor_minimo) + tr)
        this.$set(this.form,"media_desde",parseFloat(this.form.minima_hasta) + 1)
        this.$set(this.form,"media_hasta",parseFloat(this.form.minima_hasta) + tr)
        this.$set(this.form,"maxima_desde",parseFloat(this.form.media_hasta) + 1)
        this.$set(this.form,"maxima_hasta", this.form.valor_maximo)
      }
    }
  }
}
</script>